import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { Header } from './Header'
import { useAppSelector } from '../../redux/store'
import { useLogoutUserMutation } from '../../redux/api/authApi'
import { NoAccessLogoutModal } from './NoAccessLogoutModal'

const APP_NAME = 'intranet'

export const Layout = () => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [logoutUser, { isLoading, isSuccess }] = useLogoutUserMutation()
  const user = useAppSelector((state) => state.userState.user)
  const closeModalHandler = async () => {
    await logoutUser()
    setOpenModal(false)
    navigate('/login')
  }
  useEffect(() => {
    if (
      user?.userRoleList &&
      !user?.userRoleList.map((obj: any) => obj.application.toLowerCase().trim()).includes(APP_NAME)
    ) {
      user && setOpenModal(true)
    } else {
      setOpenModal(false)
    }
  }, [user])
  return (
    <>
      <NoAccessLogoutModal open={openModal} onClose={closeModalHandler} />
      <Header />
      <Outlet />
    </>
  )
}
