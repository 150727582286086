import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { agentApi } from './api/agentApi'
import { applicationApi } from './api/applicationApi'
import { dealerGroupAPi } from './api/dealerGroupApi'
import { saleSourceApi } from './api/saleSourceApi'
import { productStatusApi } from './api/productStatusApi'
import { triggerFactorApi } from './api/triggerFactorApi'
import { leadTypeApi } from './api/leadTypeApi'
import { dateTriggerTypeApi } from './api/dateTriggerTypeApi'
import { marketingTypeApi } from './api/marketingTypeApi'
import { solicitationApi } from './api/solicitationApi'
import { authApi } from './api/authApi'
import { bounceApi } from './api/bounceApi'
import { dispatchMessageApi } from './api/dispatchMessageMasterApi'
import { flightTrackerConsumerStatusApi } from './api/flightTrackerConsumerStatusApi'
import { roleApi } from './api/roleApi'
import { solicitationPurchaseIncompleteApi } from './api/reports/solicitationPurchaseIncompleteApi'
import { surveyMonkeyApi } from './api/surveyMonkeyMasterApi'
import { userApi } from './api/userApi'

import applicationReducer from './slice/applicationSlice'
import dealerGroupReducer from './slice/dealerGroupSlice'
import leadTypeReducer from './slice/leadTypeSlice'
import authReducer from './slice/authSlice'
import dateTriggerTypeReducer from './slice/dateTriggerTypeSlice'
import dispatchMessageReducer from './slice/dispatchMessageSlice'
import flightTrackerConsumerStatusReducer from './slice/flightTrackerConsumerStatusSlice'
import marketingTypeReducer from './slice/marketingTypeSlice'
import surveyMonkeyReducer from './slice/surveyMonkeySlice'
import { dealerGroupNumberApi } from './api/dealergroupnumberApi'
import triggerFactorReducer from './slice/triggerFactorSlice'
import userReducer from './slice/userSlice'
import yearDetailsReducer from './slice/yearDetailsSlice'

export const store = configureStore({
  reducer: {
    [agentApi.reducerPath]: agentApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [dealerGroupAPi.reducerPath]: dealerGroupAPi.reducer,
    [triggerFactorApi.reducerPath]: triggerFactorApi.reducer,
    [leadTypeApi.reducerPath]: leadTypeApi.reducer,
    [dealerGroupNumberApi.reducerPath]: dealerGroupNumberApi.reducer,
    [marketingTypeApi.reducerPath]: marketingTypeApi.reducer,
    [saleSourceApi.reducerPath]: saleSourceApi.reducer,
    [productStatusApi.reducerPath]: productStatusApi.reducer,
    [solicitationApi.reducerPath]: solicitationApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [bounceApi.reducerPath]: bounceApi.reducer,
    [dateTriggerTypeApi.reducerPath]: dateTriggerTypeApi.reducer,
    [dispatchMessageApi.reducerPath]: dispatchMessageApi.reducer,
    [flightTrackerConsumerStatusApi.reducerPath]: flightTrackerConsumerStatusApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [solicitationPurchaseIncompleteApi.reducerPath]: solicitationPurchaseIncompleteApi.reducer,
    [surveyMonkeyApi.reducerPath]: surveyMonkeyApi.reducer,
    [userApi.reducerPath]: userApi.reducer,

    applicationState: applicationReducer,
    dealerGroupState: dealerGroupReducer,
    triggerFactorState: triggerFactorReducer,
    leadTypeState: leadTypeReducer,
    authState: authReducer,
    dateTriggerTypeState: dateTriggerTypeReducer,
    dispatchMessageState: dispatchMessageReducer,
    flightTrackerConsumerStatusState: flightTrackerConsumerStatusReducer,
    marketingTypeState: marketingTypeReducer,
    surveyMonkeyState: surveyMonkeyReducer,
    userState: userReducer,
    yearDetailSate: yearDetailsReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      agentApi.middleware,
      applicationApi.middleware,
      dealerGroupAPi.middleware,
      triggerFactorApi.middleware,
      leadTypeApi.middleware,
      dateTriggerTypeApi.middleware,
      marketingTypeApi.middleware,
      saleSourceApi.middleware,
      productStatusApi.middleware,
      solicitationApi.middleware,
      authApi.middleware,
      bounceApi.middleware,
      dateTriggerTypeApi.middleware,
      dealerGroupNumberApi.middleware,
      dispatchMessageApi.middleware,
      flightTrackerConsumerStatusApi.middleware,
      leadTypeApi.middleware,
      marketingTypeApi.middleware,
      productStatusApi.middleware,
      roleApi.middleware,
      saleSourceApi.middleware,
      solicitationApi.middleware,
      solicitationPurchaseIncompleteApi.middleware,
      dealerGroupNumberApi.middleware,
      surveyMonkeyApi.middleware,
      triggerFactorApi.middleware,
      userApi.middleware,
    ]),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
