import React from 'react'
import { useLogoutUserMutation } from '../../redux/api/authApi'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 1,
  outline: 0, // Remove default outline
}

interface Props {
  open: boolean
  onClose: () => void // Add onClose prop to handle closing the modal
}

export const NoAccessLogoutModal = ({ open, onClose }: Props) => {
  const [logoutUser, { isLoading, isSuccess }] = useLogoutUserMutation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      PaperProps={{ sx: style }}
    >
      <DialogContent>
        <Typography id='modal-description' sx={{ mt: 2 }}>
          Uh oh! It appears you do not have access to this application. Please contact IT to get
          access.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={onClose} sx={{ mt: 2 }}>
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  )
}
