import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'

export const dealerGroupNumberApi = createApi({
  reducerPath: 'dealerGroupApi',
  baseQuery: customOpwFetchBase,
  endpoints: (builder) => ({
    getDealerGroupNumberData: builder.query<any, void>({
      query: () => ({
        url: 'dealerGrouplist',
        credentials: 'include',
      }),
      transformResponse: (result: any) => result.data,
    }),
  }),
})

export const { useGetDealerGroupNumberDataQuery } = dealerGroupNumberApi
