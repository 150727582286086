import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Layout } from './components'
import { ElectroluxLayout } from './components/Electrolux/Layout/Index'
import { ProtectedPage } from './components/ProtectedPage'

import {
  ApplicationManagementPage,
  ConsumerPortalStatusPage,
  DateTriggerTypePage,
  DispatchMessage,
  HomePage,
  LeadTypePage,
  LoginPage,
  MarketingTypePage,
  ProductStatusManagementPage,
  ResetPassword,
  SaleSourceManagementPage,
  SurveyMonkeyMasterPage,
  TriggerFactorPage,
  UserManagementPage,
  UserRoleManagementPage,
} from './pages'
import { ElectroluxPortal } from './pages/Agent/Electrolux/Portal'
import { SearchResult } from './pages/Agent/Electrolux/SearchResult'
import { AgentUserManagementPage } from './pages/Agent/Users/AgentUserManagementPage'
import { ScheduleService } from './pages/Bounce/ScheduleService'
import { ServiceSuccess } from './pages/Bounce/ServiceSuccess'
import { DealerGroupPage } from './pages/DealerGroups'

import { SolicitationPurchaseIncompleteReport } from './reports/SolicitationPurchaseIncomplete'

import 'react-toastify/dist/ReactToastify.css'

export const App = () => {
  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route element={<ProtectedPage />}>
            <Route index element={<HomePage />} />
          </Route>
          <Route path='agent' element={<ProtectedPage allowedUserRoleList={['Admin']} />}>
            <Route path='users' element={<AgentUserManagementPage />} />
          </Route>
          <Route
            path='reports'
            element={
              <ProtectedPage
                allowedUserRoleList={['Admin']}
                allowedApplicationList={['Intranet']}
              />
            }
          >
            <Route
              path='incompletesolicitations'
              element={<SolicitationPurchaseIncompleteReport />}
            />
          </Route>
          <Route
            path='admin'
            element={
              <ProtectedPage
                allowedUserRoleList={['Admin']}
                allowedApplicationList={['Intranet']}
              />
            }
          >
            <Route path='dispatchmessage' element={<DispatchMessage />} />
            <Route path='surveymonkeymaster' element={<SurveyMonkeyMasterPage />} />
            <Route path='flighttrackerconsumer' element={<ConsumerPortalStatusPage />} />
            <Route path='users' element={<UserManagementPage />} />
            <Route path='roles' element={<UserRoleManagementPage />} />
            <Route path='applications' element={<ApplicationManagementPage />} />
            <Route path='salesources' element={<SaleSourceManagementPage />} />
            <Route path='productstatuses' element={<ProductStatusManagementPage />} />
            <Route path='triggerfactors' element={<TriggerFactorPage />} />
            <Route path='marketingtypes' element={<MarketingTypePage />} />
            <Route path='leadtypes' element={<LeadTypePage />} />
            <Route path='datetriggertypes' element={<DateTriggerTypePage />} />
            <Route path='surveymonkeymaster' element={<SurveyMonkeyMasterPage />} />
            <Route path='dealergroups' element={<DealerGroupPage />} />
          </Route>
        </Route>
        <Route
          path='electrolux'
          element={<ProtectedPage allowedApplicationList={['Electrolux', 'Intranet']} />}
        >
          <Route element={<ElectroluxLayout />}>
            <Route path='home' element={<ElectroluxPortal />} />
            <Route path='search-results' element={<SearchResult />} />
          </Route>
        </Route>
        <Route path='login' element={<LoginPage />} />
        <Route path='password/reset/:token' element={<ResetPassword />} />
        <Route path='bounce/scheduleService' element={<ScheduleService />} />
        <Route path='bounce/scheduleService/success' element={<ServiceSuccess />} />
        <Route path='*' element={<LoginPage />} />
      </Routes>
    </React.Fragment>
  )
}
