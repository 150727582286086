/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  Modal,
  Grid,
  FormControlLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { IDealerGroups } from '../../types'
import {
  useCreateDealerGroupMutation,
  useUpdateDealerGroupMutation,
} from '../../redux/api/dealerGroupApi'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
}
interface IDealerGroup {
  dealerGroupName: string
  dealerGroupNumber: string
}
interface Props {
  editableDealerGroup?: IDealerGroups
  onClose: () => void
  refetchDealerGroups: () => void
  open: boolean
  dealerGroupNumbersList: IDealerGroup[]
  setDealerList: React.Dispatch<React.SetStateAction<IDealerGroup[]>>
  dealerList: IDealerGroup[]
  resetForm: string
}
const validationSchema = yup.object({
  dealerGroupName: yup.string().required('DealerGroup name is required'),
  logoImg: yup.mixed().notRequired(),
})

export const DealerGroupForm = ({
  onClose,
  refetchDealerGroups,
  open,
  editableDealerGroup,
  dealerGroupNumbersList,
  dealerList,
  setDealerList,
  resetForm,
}: Props) => {
  const [createDealerGroup, { isLoading, isError, error, isSuccess }] =
    useCreateDealerGroupMutation()
  const [
    updateDealerGroups,
    { isLoading: uIsLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateDealerGroupMutation()
  const [dealerGroupRemoved, setDealerGroupRemoved] = useState<IDealerGroup>()
  const [logoImg, setLogoImg] = React.useState<File | null>(null)
  const [logoImgUrl, setLogoImgUrl] = React.useState<any>(null)
  const formik = useFormik({
    initialValues: {
      application: editableDealerGroup?.application ?? 'Intranet',
      dealerGroupName: editableDealerGroup?.dealerGroupName ?? '',
      dealerGroupNumber: editableDealerGroup?.dealerGroupNumber ?? '',
      dealerLogoPath: editableDealerGroup?.dealerLogoPath ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      const requestData = {
        application: values?.application ?? 'Intranet',
        dealerGroupName: values?.dealerGroupName ?? '',
        dealerGroupNumber: values?.dealerGroupNumber ?? '',
        dealerLogoPath: values?.dealerLogoPath ?? '',
        logoImg: logoImg,
      }
      if (editableDealerGroup?.dealerGroupNumberToLogoId) {
        updateDealerGroups({
          dealerGroupNumberToLogoId: editableDealerGroup?.dealerGroupNumberToLogoId,
          ...requestData,
        })
          .unwrap()
          .then(() => {
            refetchDealerGroups()
            if (dealerGroupRemoved) {
              const filteredArray = dealerGroupNumbersList.filter(
                (item1) => item1.dealerGroupNumber !== dealerGroupRemoved.dealerGroupNumber,
              )
              setDealerList(filteredArray)
            }
          })
      } else {
        createDealerGroup({
          ...requestData,
        })
          .unwrap()
          .then(() => {
            refetchDealerGroups()
            if (dealerGroupRemoved) {
              const filteredArray = dealerGroupNumbersList.filter(
                (item1) => item1.dealerGroupNumber !== dealerGroupRemoved.dealerGroupNumber,
              )
              setDealerList(filteredArray)
            }
          })
      }
    },
  })

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetchDealerGroups()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])
  React.useEffect(() => {
    if (uIsSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetchDealerGroups()
    }
    if (uIsError) {
      if (Array.isArray((uError as any)?.data?.error)) {
        const errors = uError as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((uError as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [uIsLoading])

  React.useEffect(() => {
    if (editableDealerGroup) {
      formik.setValues({
        application: editableDealerGroup?.application ?? '',
        dealerGroupName: editableDealerGroup?.dealerGroupName ?? '',
        dealerGroupNumber: editableDealerGroup?.dealerGroupNumber ?? '',
        dealerLogoPath: editableDealerGroup?.dealerLogoPath ?? '',
      })
    }
  }, [editableDealerGroup])

  React.useEffect(() => {
    if (!open) resetImg()
  }, [open])

  const resetImg = () => {
    setLogoImg(null)
    setLogoImgUrl(null)
  }

  const reset = () => {
    if (editableDealerGroup) {
      const filteredArray = dealerGroupNumbersList.filter(
        (item1) => item1.dealerGroupNumber !== editableDealerGroup.dealerGroupNumber,
      )
      setDealerList(filteredArray)
    }
    setLogoImg(null)
    setLogoImgUrl(null)
    formik.setValues({
      application: '',
      dealerGroupName: '',
      dealerGroupNumber: '',
      dealerLogoPath: '',
    })
    formik.resetForm()
    onClose()
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            Dealer Group Logo Details
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <Select
                fullWidth
                id='dealerGroup'
                name='dealerGroupName'
                value={formik.values.dealerGroupName}
                onChange={(event) => {
                  const selectedDealerGroup = dealerGroupNumbersList.find(
                    (group) => group.dealerGroupName === event.target.value,
                  )
                  if (selectedDealerGroup) {
                    setDealerGroupRemoved(selectedDealerGroup)
                  }
                  if (selectedDealerGroup) {
                    formik.setFieldValue('dealerGroupName', selectedDealerGroup.dealerGroupName)
                    formik.setFieldValue('dealerGroupNumber', selectedDealerGroup.dealerGroupNumber)
                  } else {
                    // Handle the case where no matching dealer group is found (optional)
                    formik.setFieldValue('dealerGroupName', '')
                    formik.setFieldValue('dealerGroupNumber', '')
                  }
                }}
                error={formik.touched.dealerGroupName && Boolean(formik.errors.dealerGroupName)}
              >
                {dealerGroupNumbersList.map((group) => (
                  <MenuItem key={group.dealerGroupNumber} value={group.dealerGroupName}>
                    {group.dealerGroupName}
                  </MenuItem>
                ))}
              </Select>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <>
                        <TextField
                          id='logoImg'
                          name='logoImg'
                          type='file'
                          inputProps={{
                            multiple: false,
                          }}
                          sx={{ display: 'none' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            if (e.target.files && e.target.files[0]) {
                              setLogoImgUrl(URL.createObjectURL(e.target.files[0]))
                              setLogoImg(e.target.files[0])
                            }
                          }}
                        />
                        <Button color='primary' variant='contained' component='span'>
                          + Logo
                        </Button>
                      </>
                    }
                    htmlFor='logoImg'
                    label=''
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign='center'>
                    <img
                      src={
                        logoImgUrl
                          ? logoImgUrl
                          : resetForm == 'update' && formik.values.dealerLogoPath
                      }
                      width='100px'
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Box sx={{ textAlign: 'right', mt: '30px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                color='error'
                onClick={reset}
                disabled={uIsLoading || isLoading}
              >
                {uIsLoading || isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Cancel'
                )}
              </Button>
              <Button
                variant='contained'
                sx={{ borderRadius: '20px' }}
                size='large'
                type='submit'
                disabled={uIsLoading || isLoading}
              >
                {uIsLoading || isLoading ? (
                  <CircularProgress color='primary' size={24} />
                ) : (
                  'Submit'
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
