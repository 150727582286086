import * as React from 'react'
import { useNavigate } from 'react-router'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PeopleIcon from '@mui/icons-material/People'
import RuleIcon from '@mui/icons-material/Rule'
import ShieldIcon from '@mui/icons-material/Shield'

interface Props {
  admins: (string | undefined)[]
}

export function Admins({ admins }: Props) {
  const navigate = useNavigate()
  const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null)

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null)
  }

  const handleOpenAdminMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAdmin(event.currentTarget)
  }

  const goToApplications = () => {
    navigate('/admin/applications')
    handleCloseAdminMenu()
  }

  const goToDateTriggerTypes = () => {
    navigate('/admin/datetriggertypes')
    handleCloseAdminMenu()
  }

  const goToDispatchMessage = () => {
    navigate('/admin/dispatchmessage')
    handleCloseAdminMenu()
  }

  const goToLeadTypes = () => {
    navigate('/admin/leadtypes')
    handleCloseAdminMenu()
  }

  const goToMarketingTypes = () => {
    navigate('/admin/marketingtypes')
    handleCloseAdminMenu()
  }

  const goToProductStatuses = () => {
    navigate('/admin/productStatuses')
    handleCloseAdminMenu()
  }

  const goToRoles = () => {
    navigate('/admin/roles')
    handleCloseAdminMenu()
  }

  const goToSaleSources = () => {
    navigate('/admin/saleSources')
    handleCloseAdminMenu()
  }

  const goToSurveyMonkeyMaster = () => {
    navigate('/admin/surveymonkeymaster')
    handleCloseAdminMenu()
  }

  const goToConsumerPortalFlightTracker = () => {
    navigate('/admin/flighttrackerconsumer')
    handleCloseAdminMenu()
  }

  const goToTriggerFactors = () => {
    navigate('/admin/triggerFactors')
    handleCloseAdminMenu()
  }

  const goToUsers = () => {
    if (admins?.includes('intranet')) {
      navigate('/admin/users')
    } else {
      navigate('/agent/users')
    }
    handleCloseAdminMenu()
  }

  const goToDealerGroup = () => {
    navigate('/admin/dealergroups')
    handleCloseAdminMenu()
  }
  return (
    <React.Fragment>
      <Button
        onClick={handleOpenAdminMenu}
        sx={{ color: 'white', textDecorationLine: 'none' }}
        startIcon={<ContentPasteOutlinedIcon />}
        endIcon={anchorElAdmin ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      >
        Admin
      </Button>
      <Menu
        sx={{ mt: '45px' }}
        id='menu-appbar'
        anchorEl={anchorElAdmin}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElAdmin)}
        onClose={handleCloseAdminMenu}
      >
        <MenuItem onClick={goToUsers}>
          <PeopleIcon sx={{ mr: 1 }} />
          <Typography textAlign='center'>Users</Typography>
        </MenuItem>
        {admins.includes('intranet') && (
          <MenuItem onClick={goToDispatchMessage}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Dispatch Messages</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToSurveyMonkeyMaster}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Survey Monkey Master</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToConsumerPortalFlightTracker}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Flight Tracker - Consumer</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToRoles}>
            <ShieldIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>User Roles</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToApplications}>
            <AppRegistrationIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Applications</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToSaleSources}>
            <AppRegistrationIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Sale Sources</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToProductStatuses}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Product Statuses</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToTriggerFactors}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Trigger Factors</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToLeadTypes}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Lead Types</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToDateTriggerTypes}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Date Trigger Types</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToMarketingTypes}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Marketing Types</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToSurveyMonkeyMaster}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>Survey Monkey Master</Typography>
          </MenuItem>
        )}
        {admins.includes('intranet') && (
          <MenuItem onClick={goToDealerGroup}>
            <RuleIcon sx={{ mr: 1 }} />
            <Typography textAlign='center'>DealerGroup</Typography>
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}
