import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDealerGroups } from '../../types'

interface IDealerGroupState {
  dealerGroups: IDealerGroups | null
}

const initialState: IDealerGroupState = {
  dealerGroups: null,
}

export const dealerGroupSlice = createSlice({
  initialState,
  name: 'dealerGroupSlice',
  reducers: {
    setDealerGroup: (state, action: PayloadAction<IDealerGroups>) => {
      state.dealerGroups = action.payload
    },
  },
})

export default dealerGroupSlice.reducer

export const { setDealerGroup } = dealerGroupSlice.actions
