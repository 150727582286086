import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  GenericResponse,
  IUpdateDealerGroupStatusRequest,
  IUpdateDealerGroupRequest,
  ICreatedealerGroupRequest,
  ActiveFlg,
  IDealerGroups,
} from '../../types'
import { setDealerGroup } from '../slice/dealerGroupSlice'

export const dealerGroupAPi = createApi({
  reducerPath: 'dealerGroupapi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['Application'],
  endpoints: (builder) => ({
    getApplication: builder.mutation<IDealerGroups, string>({
      query(application) {
        return {
          url: `applications/get?application=${application}`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: IDealerGroups }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setDealerGroup(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getDealerGroupList: builder.query<IDealerGroups[], ActiveFlg | null>({
      query(data) {
        return {
          url: `dealergroups/get_list/${data ? 'active' : 'all'}`,
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { dealerGroupList: any } }) => {
        // Return the dealerGroupList as usual
        return result.data.dealerGroupList
      },
    }),
    createDealerGroup: builder.mutation<GenericResponse, ICreatedealerGroupRequest>({
      query(data) {
        const formData = new FormData()
        formData.append('application', data.application ?? '')
        formData.append('dealerLogoPath', data.dealerLogoPath ?? '')
        formData.append('dealerGroupName', data.dealerGroupName ?? '')
        formData.append('dealerGroupNumber', data.dealerGroupNumber ?? '')
        formData.append('logoImg', data.logoImg ?? '')

        return {
          url: 'dealergroups/create',
          method: 'POST',
          body: formData,
          credentials: 'include',
        }
      },
    }),
    updateDealerGroup: builder.mutation<GenericResponse, IUpdateDealerGroupRequest>({
      query(data) {
        const formData = new FormData()
        formData.append(
          'dealerGroupNumberToLogoId',
          data.dealerGroupNumberToLogoId.toString() ?? '',
        )
        formData.append('application', data.application ?? '')
        formData.append('dealerLogoPath', data.dealerLogoPath ?? '')
        formData.append('dealerGroupName', data.dealerGroupName ?? '')
        formData.append('dealerGroupNumber', data.dealerGroupNumber ?? '')
        formData.append('logoImg', data.logoImg ?? '')
        return {
          url: 'dealergroups/update',
          method: 'POST',
          body: formData,
          headers: {},
          credentials: 'include',
        }
      },
    }),
    updateDealerGroupStatus: builder.mutation<GenericResponse, IUpdateDealerGroupStatusRequest>({
      query(data) {
        return {
          url: 'dealergroups/update_status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetApplicationMutation,
  useGetDealerGroupListQuery,
  useCreateDealerGroupMutation,
  useUpdateDealerGroupMutation,
  useUpdateDealerGroupStatusMutation,
} = dealerGroupAPi
